import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Select } from "clutch/src/Select/Select.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";
import type { TFunction } from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import { ScoreText } from "@/game-val/CommonComponent.jsx";
import { RANK_IMGS, VAL_REGIONS } from "@/game-val/constants.mjs";
import {
  FILTERS,
  getFiltersFromSearchParams,
  getLeaderboardData,
} from "@/game-val/leaderboard-utils.mjs";
import SelectAct from "@/game-val/SelectAct.jsx";
import { getNameTag, getValorantRankImage } from "@/game-val/utils.mjs";
import ContentContainer from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const PlayerCol = styled("a")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-start;
  flex-grow: 1;
  padding-left: var(--sp-3);

  img {
    width: var(--sp-7);
    height: var(--sp-7);
    margin-right: var(--sp-3);
  }
`;

export const playerName = () => css`
  text-align: start;
  overflow: hidden;
`;

const COLS = (t: TFunction) => [
  {
    display: t("val:rank", "Rank"),
    primary: true,
  },
  {
    display: t("common:player", "Player"),
  },
  {
    display: t("val:leaderboard:rr", "RR"),
  },
  {
    display: t("val:stats.wins", "Games Won"),
  },
];

const TableContainer = styled("div")`
  .infinite-table {
    min-width: auto;
  }
`;

function TableView({ search }) {
  const { t } = useTranslation();
  const { currentPath, searchParams } = useRoute();

  const {
    val: { leaderboard = {}, content },
  } = useSnapshot(readState);

  const data = getLeaderboardData(leaderboard, searchParams, content);
  const cols = COLS(t);

  const filteredData = useMemo(() => {
    if (!data) return null;
    return data.filter(
      (d) =>
        d.gameName &&
        d.tagLine &&
        `${d.gameName}-${d.tagLine}`
          .toLowerCase()
          .search(search.toLowerCase()) >= 0,
    );
  }, [data, search]);

  const loadMore = useCallback(() => {
    updateRoute(currentPath, searchParams, {
      offset: filteredData[filteredData.length - 1]?.position || 0,
    });
  }, [currentPath, searchParams, filteredData]);

  const rows = useMemo(() => {
    if (!filteredData) return null;
    return filteredData.map(({ position, gameName, tagLine, rating, wins }) => {
      const nametag = getNameTag(gameName, tagLine);
      return [
        {
          display: position,
          value: position,
        },
        {
          display: (
            <PlayerCol href={`/valorant/profile/${nametag}`}>
              <img
                src={getValorantRankImage({
                  tier: position > 500 ? "immortal" : "radiant",
                  rank: position > 500 ? 23 : 24,
                  size: "small",
                })}
                alt={position}
              />
              <span className="type-body2-form--active" data-tip={nametag}>
                {gameName}
              </span>
            </PlayerCol>
          ),
          value: gameName,
        },
        {
          display: (
            <ScoreText className={"type-body2"} $score={rating}>
              {rating}
            </ScoreText>
          ),
          value: rating,
        },
        {
          display: getLocaleString(wins, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }),
          value: wins,
        },
      ];
    });
  }, [filteredData]);

  return (
    <TableContainer>
      <DataTable
        cols={cols}
        rows={rows}
        indexCol={false}
        sortCol={0}
        sortDir="ASC"
      />
      {filteredData?.length > 0 && !search && (
        <Button block onClick={loadMore}>
          {t("common:loadMore", "Load More")}
        </Button>
      )}
    </TableContainer>
  );
}

const Filters = ({ search, setSearch }) => {
  const { searchParams, currentPath, state: routeState } = useRoute();
  const { t } = useTranslation();

  const {
    val: { content },
  } = useSnapshot(readState);

  const actName = getFiltersFromSearchParams(
    FILTERS.ACT_NAME,
    searchParams,
    content,
  );
  const rank = searchParams.get(FILTERS.RANK);
  const region = getFiltersFromSearchParams(FILTERS.REGION, searchParams);

  const updateFilters = useCallback(
    (key, val) => {
      let newOffset = routeState.offset;
      if (key === FILTERS.RANK) {
        if (val === "radiant") newOffset = 0;
        if (val === "immortal") newOffset = 500;
      }
      searchParams.set(key, val);
      updateRoute(currentPath, searchParams, {
        offset: newOffset,
      });
    },
    [routeState.offset, searchParams, currentPath],
  );

  const ranks = useMemo(
    () => [
      {
        key: "radiant",
        label: t("val:ranks.radiant", "Radiant"),
      },
      {
        key: "immortal",
        label: t("val:ranks.immortal", "Immortal"),
      },
    ],
    [t],
  );

  return (
    <FilterBar>
      <TextInput
        defaultValue={search}
        placeholder={t("common:searchPlayers", "Search Players")}
        onValueChange={(v) => setSearch(v)}
      />
      <SelectAct
        selected={actName}
        onChange={(v) => updateFilters(FILTERS.ACT_NAME, v)}
        inputStyle
      />
      <Select
        selected={region}
        options={VAL_REGIONS.map((r) => ({
          value: r.value,
          text: [`val:region.${r.value}`, r.text],
        }))}
        onChange={(v) => updateFilters(FILTERS.REGION, v)}
      />
      <ButtonGroup className="align-right">
        {ranks.map(({ key, label }) => (
          <Button
            key={key}
            className={rank === key ? "active" : ""}
            onClick={() => {
              updateFilters(FILTERS.RANK, key);
            }}
            iconLeft={<img src={RANK_IMGS[key].small} alt={key} />}
          >
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </FilterBar>
  );
};

export default function Leaderboard() {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");

  return (
    <PageContainer>
      <PageHeader title={t("common:leaderboard", "Leaderboard")} />
      <ContentContainer className="flex column gap-sp-4">
        <Filters search={search} setSearch={setSearch} />
        <TableView search={search} />
      </ContentContainer>
    </PageContainer>
  );
}

export function meta() {
  return {
    title: ["val:meta.leaderboard.title", "VALORANT Leaderboards"],
    description: [
      "val:meta.leaderboard.description",
      "Climb the ranks of VALORANT with our leaderboard tracker, showcasing the top players, and their competitive standings. Compare your performance with the best, and set your sights on reaching the top.",
    ],
    subtitle: true,
  };
}
